import _cloneDeep from 'lodash/cloneDeep'
import _upperFirst from 'lodash/upperFirst'
import { IdState } from 'vue-virtual-scroller'
import moment from 'moment-timezone'
import { genericApiRequests } from '@/utilities/axios-factory'

import {
  D_CLEAR_BUS_STOP_JOB_ERROR,
  D_UPDATE_BUS_STOP
} from '../../../utilities/action-types'

import {
  formatDate,
  formatTime
} from '@smarttransit/common'

import {
  addParamsToLocation,
  generateKeywordsQueryForOr
} from '@smarttransit/common-client'

import {
  getCachedRegions,
  isAuthorized,
  addAlert
} from '../../../utilities/helpers'

import {
  findBusStops,
  findTotalBusStops,
  getAllBusStopAudioByBusStopIds,
  getBusStopJobStatus,
  startBusStopJob,
  stopBusStopJob
} from '../../../services/bus-stops-service'

import {
  getBusStopData,
  // getCoarseGeolocation,
  getRoute,
  getRouteByRouteIdList
  // googleGeocodeRequest
} from '@/services/maps-service'

import { getBusStopsForProfileRoute, getProfileRoute } from '@/services/transportation-profile-routes-service'

const MAX_BULK_ITEMS = 100000

export default {
  name: 'bus-stops',
  props: {
    signedInUser: Object
  },
  mixins: [
    IdState({
      idProp: (vm) => vm.item && vm.item.id
    })
  ],
  idState () {
    return {
      currentBulkEditItem: null
    }
  },
  data () {
    return {
      syncMenu: false,
      selectedBusStop: null,
      refreshMapBusStops: null,
      modalBusStopUpdate: false,
      modalGenericAudioUpdate: false,
      isEditable: false,
      isSyncPossible: false,
      busStopsListHeight: '462px',
      busStopsHeaderHeight: 0,
      longLatOnMap: null,
      searchKeywords: '',
      selectedRegion: null,
      selectedSortFilter: 'name_asc',
      busStops: [],
      busStopsInBulk: [],
      totalBusStops: 0,
      apiInProgress: false,
      busStopAudioApiInProgress: false,
      currentJobStatusText: '',
      currentJobStatus: null,
      checkingCurrentJobStatus: false,
      csvDataFilename: '',
      modalSiteAlert: false,
      modalSiteAlertData: null,
      closingBulkEdit: false,
      enableVirtualList: false,
      pagination: {
        page: 1,
        rowsPerPage: 5
      },
      isBulkEdit: false,
      totalVisibleBulkEditsUpdated: 0,
      regions: [{ text: 'Duplicate OTP names', value: 'duplicates' }],
      locales: [
        { text: 'English', value: 'en-GB', routers: ['accra'] },
        { text: 'Twi', value: 'tw-GH', routers: ['accra'] },
        { text: 'Ga', value: 'ga-GH', routers: ['accra'] }
      ],
      sortFilters: [
        { text: 'Name ASC', value: 'name_asc' },
        { text: 'Name DESC', value: 'name_desc' },
        { text: 'OTP Name ASC', value: 'otp_name_asc' },
        { text: 'OTP Name DESC', value: 'otp_name_desc' },
        { text: 'Date Updated ASC', value: 'date_updated_asc' },
        { text: 'Date Updated DESC', value: 'date_updated_desc' }
      ],
      stMapApis: null,
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      maptilerKey: process.env.VUE_APP_MAPTILER_KEY
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onResize()

      if (this.$route.query.sort) {
        this.selectedSortFilter = this.$route.query.sort
      }

      if (this.$route.query.bulkedit && this.$route.query.bulkedit !== '0') {
        this.$data.isBulkEdit = true

        getCachedRegions().then((regions) => {
          this.bulkEditRegions = []

          if (regions) {
            regions.forEach((region) => {
              this.bulkEditRegions.push({ text: _upperFirst(region.routerId), value: region })
            })
          }

          this.selectedRegion = this.bulkEditRegions.length ? this.bulkEditRegions[0].value : null
        })
      }
    })

    this.isEditable = isAuthorized(this.$props.signedInUser, 'staff')
    this.isSyncPossible = isAuthorized(this.$props.signedInUser, 'admin')

    this.isBulkEditWatchHandle = this.$watch(() => { return [this.$data.isBulkEdit, this.$data.selectedRegion] }, (newVal) => {
      if (newVal && newVal[0] && newVal[1]) {
        this.initBulkEdit()
      }
    })

    this.stMapApis = {
      genericApiRequests,
      getRouteByRouteIdList,
      getRoute,
      getBusStopsForProfileRoute,
      getTransportationProfileRoute: getProfileRoute,
      addAlert,
      getBusStops: ({ searchradius, ...rest }) => (getBusStopData({ ...rest, radius: searchradius })),
      getCachedRegions
    }
  },
  watch: {
    selectedRegion (val) {
      if (!this.$data.isBulkEdit) {
        this.searchBusStops()
      }
      if (val) {
        this.checkJobStatus('forceStart')
      }
    },
    selectedSortFilter (val) {
      // window.location.hash
      this.lastParams = addParamsToLocation({ sort: val }, this.$route, this.lastParams)
      if (this.selectedRegion) {
        this.searchBusStops()
      }
    }
  },
  computed: {
    filteredLocales () {
      return this.selectedRegion ? this.locales.filter((o) => (o.routers.includes(this.selectedRegion.routerId))) : this.locales
    }
  },
  methods: {
    onPagination () {
      if (this.selectedRegion) {
        this.searchBusStops()
      }
    },
    addAlert (error) {
      if (this.$data.isBulkEdit) {
        this.$data.modalSiteAlert = true
        this.$data.modalSiteAlertData = error
      } else {
        addAlert({
          message: error.message,
          type: error.type || 'error'
        })
      }
    },
    onResize () {
      const pageBannerHeight = this.$store.getters.appToolbarHeight
      const paginationElementHeight = 49
      const busStopsListElement = this.$refs?.busStopsListHeader || null
      const busStopsHeaderElement = this.$refs?.busStopsHeader || null
      let height = busStopsListElement ? window.innerHeight - busStopsListElement.clientHeight : window.innerHeight
      height = busStopsHeaderElement ? height - busStopsHeaderElement.clientHeight : window.innerHeight
      this.busStopsListHeight = (height - (paginationElementHeight + pageBannerHeight)) + 'px'
      this.busStopsHeaderHeight = (busStopsHeaderElement?.clientHeight || 0) + pageBannerHeight
    },
    async initBulkEdit () {
      await this.searchBusStopsInBulk()

      this.$nextTick(() => {
        this.$data.enableVirtualList = true
      })

      if (this.totalBusStops > MAX_BULK_ITEMS) {
        return this.addAlert({
          message: `Bulk edit can only process ${MAX_BULK_ITEMS} items, search results found ${this.totalBusStops} items`,
          type: 'warning'
        })
      }

      this.initBulkEditVisibleRecordUpdateChecks()
    },
    visibleBulkEditIndexes (startIndex, endIndex) {
      this.visibleBulkEditIndexObj = { startIndex, endIndex }
      if ((startIndex || startIndex === 0) && endIndex) {
        this.clonedBusStops = this.$data.busStops.slice(startIndex, endIndex)
        this.clonedBusStops = this.clonedBusStops && this.clonedBusStops.map((o) => _cloneDeep(o))
      } else {
        this.clonedBusStops = []
      }
    },
    // Have any of the visible records been updated? (dateUpdated is more recent)
    initBulkEditVisibleRecordUpdateChecks () {
      if (this.initBulkEditVisibleRecordUpdateChecksHandle) {
        clearTimeout(this.initBulkEditVisibleRecordUpdateChecksHandle)
      }
      this.initBulkEditVisibleRecordUpdateChecksHandle = setTimeout(async () => {
        const { startIndex, endIndex } = this.visibleBulkEditIndexObj || {}
        if ((startIndex || startIndex === 0) && endIndex) {
          const busStopsToQuery = this.$data.busStops.slice(startIndex, endIndex)

          const orQueries = busStopsToQuery.map((o) => {
            return {
              and: [{ id: o.id }, { dateUpdated: { gt: moment.utc(o.dateUpdated).valueOf() } }]
            }
          })

          const result = await findTotalBusStops({
            or: orQueries
          })

          if (result && result.count) {
            this.$data.totalVisibleBulkEditsUpdated = { count: result.count, startIndex, endIndex }
            console.log('totalVisibleBulkEditsUpdated', this.$data.totalVisibleBulkEditsUpdated)
          }
        }
        return this.initBulkEditVisibleRecordUpdateChecks()
      }, 5 * 60000)
    },
    async busStopNamePossiblyEdited (busStop) {
      const clonedBusStop = this.clonedBusStops.find((o) => o.id === busStop.id)
      if (busStop.name && clonedBusStop && clonedBusStop.name !== busStop.name) {
        // change has been made, so perform update
        try {
          this.idState.currentBulkEditItem = busStop
          this.idState.currentBulkEditItem.apiState = 'loading'
          let result = await this.$store.dispatch(D_UPDATE_BUS_STOP, { id: busStop.id, name: busStop.name })
          result = this.marshalBusStop(result)
          this.idState.currentBulkEditItem = result
          this.idState.currentBulkEditItem.apiState = 'success'
          const busStopIndex = this.$data.busStops.findIndex((o) => o.id === busStop.id)

          if (this.$data.busStops[busStopIndex]) {
            this.$data.busStops[busStopIndex].dateUpdatedLabel = this.idState.currentBulkEditItem.dateUpdatedLabel
            this.$data.busStops[busStopIndex] = this.idState.currentBulkEditItem

            setTimeout(() => {
              this.$data.busStops[busStopIndex].apiState = null
              this.$data.busStops[busStopIndex] = _cloneDeep(this.$data.busStops[busStopIndex])
              this.idState.currentBulkEditItem = this.$data.busStops[busStopIndex]
            }, 5000)
          }

          const clonedIndex = this.clonedBusStops.findIndex((o) => o.id === busStop.id)

          if (this.clonedBusStops[clonedIndex]) {
            this.clonedBusStops[clonedIndex] = _cloneDeep(this.idState.currentBulkEditItem)
          }
        } catch (error) {
          this.idState.currentBulkEditItem.apiState = 'error'
          this.addAlert(error)
        }
      }
    },
    getBusStopIconState (item) {
      switch (item && item.apiState) {
        case 'success':
          return 'far fa-check'
        case 'error':
          return 'fas fa-exclamation-triangle'
        case 'loading':
        default:
          return 'fa fa-pencil'
      }
    },
    async searchBusStopsInBulk () {
      await this.searchBusStops(1, MAX_BULK_ITEMS)
    },
    async searchBusStops (currentPage, currentRowsPerPage) {
      if (!this.selectedRegion) {
        return this.addAlert({
          message: 'Please select a region to search bus stops by',
          type: 'error'
        })
      }

      if (!currentPage || !currentRowsPerPage) {
        const { page, rowsPerPage } = this.pagination
        currentPage = page
        currentRowsPerPage = rowsPerPage
      }

      const filter = this.generateSearchFilter(currentPage, currentRowsPerPage)
      this.apiInProgress = true

      try {
        const [results, resultsTotal] = await Promise.all([
          findBusStops(filter),
          findTotalBusStops(filter.where)
        ])

        let busStopAudioFiles = []

        if (!this.isBulkEdit && results && results.length) {
          busStopAudioFiles = await getAllBusStopAudioByBusStopIds(results.map((o) => (o.id)))
        }

        this.busStops = results.map(o => {
          return this.marshalBusStop(o, busStopAudioFiles)
        })

        this.totalBusStops = resultsTotal.count
        // this.onResize()
      } catch (err) {
        this.addAlert({
          message: `Error in retrieving bus stops: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
        // this.onResize()
      } finally {
        this.apiInProgress = false
      }
    },
    generateSearchFilter (page, rowsPerPage) {
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        where: { and: [{ routerId: this.selectedRegion.routerId }] }
      }
      if (this.searchKeywords && this.searchKeywords.trim()) {
        filter.where.and.push({ or: generateKeywordsQueryForOr(this.searchKeywords, ['name', 'externalName']) })
      }
      if (this.selectedSortFilter) {
        let sortBy, descending
        switch (this.selectedSortFilter) {
          case 'name_asc':
            sortBy = 'name'
            descending = 'ASC'
            break
          case 'name_desc':
            sortBy = 'name'
            descending = 'DESC'
            break
          case 'otp_name_asc':
            sortBy = 'externalName'
            descending = 'ASC'
            break
          case 'otp_name_desc':
            sortBy = 'externalName'
            descending = 'DESC'
            break
          case 'date_updated_asc':
            sortBy = 'dateUpdated'
            descending = 'ASC'
            break
          case 'date_updated_desc':
            sortBy = 'dateUpdated'
            descending = 'DESC'
            break
        }
        filter.order = `${sortBy} ${descending}`
      }
      return filter
    },
    marshalBusStop (busStop, busStopAudioFiles) {
      busStop.dateCreatedLabel = busStop.dateCreated && `${formatDate(busStop.dateCreated)}, ${formatTime(busStop.dateCreated)}`
      busStop.dateUpdatedLabel = busStop.dateUpdated && `${formatDate(busStop.dateUpdated)}, ${formatTime(busStop.dateUpdated)}`
      busStop.dateSyncedLabel = busStop.dateSynced && `${formatDate(busStop.dateSynced)}, ${formatTime(busStop.dateSynced)}`

      if (busStopAudioFiles && busStopAudioFiles.length) {
        busStop.audioLanguageLabels = busStopAudioFiles
          .filter((o) => (o.busStopId === busStop.id))
          .map((o) => (this.locales.find((a) => (a.value === o.locale))?.text))
      }

      return busStop
    },
    loadBusStopForm (busStop) {
      this.selectedBusStop = busStop
      this.modalBusStopUpdate = true
      this.showLongLatOnMap(busStop)
    },
    clearErrorFromOtpUpdate () {
      if (!this.selectedRegion) {
        return addAlert({
          message: 'Please select a region to continue clearing the job error',
          type: 'error'
        })
      }

      this.checkingCurrentJobStatus = true

      this.$store.dispatch(D_CLEAR_BUS_STOP_JOB_ERROR, this.selectedRegion.routerId).then((result) => {
        if (result.success) {
          addAlert({
            message: 'Bus stops sync job error cleared',
            type: 'success',
            transient: true
          })
          this.checkJobStatus('forceStart')
        } else {
          addAlert({
            message: 'The bus stops sync job error could not be cleared',
            type: 'error'
          })
        }
      }).catch((err) => {
        addAlert({
          message: `Error in clearing error in bus stop sync job: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }).finally(() => {
        this.checkingCurrentJobStatus = false
      })
    },
    updateFromOtp () {
      if (!this.selectedRegion) {
        return addAlert({
          message: 'Please select a region to continue job start request',
          type: 'error'
        })
      }

      this.checkingCurrentJobStatus = true

      startBusStopJob(this.selectedRegion.routerId).then((result) => {
        if (result.success) {
          addAlert({
            message: 'Bus stops sync job started',
            type: 'success',
            transient: true
          })
        } else {
          addAlert({
            message: 'The bus stops sync job could not be started',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.checkJobStatus('forceStart')
        }, 3000)
      }).catch((err) => {
        addAlert({
          message: `Error in starting bus stop sync job: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }).finally(() => {
        this.checkingCurrentJobStatus = false
      })
    },
    stopUpdateFromOtp () {
      if (!this.selectedRegion) {
        return addAlert({
          message: 'Please select a region to continue job stop request',
          type: 'error'
        })
      }

      this.checkingCurrentJobStatus = true

      stopBusStopJob(this.selectedRegion.routerId).then((result) => {
        if (result.success) {
          addAlert({
            message: 'Bus stops sync job stopped',
            type: 'success',
            transient: true
          })
        } else {
          addAlert({
            message: 'The bus stops sync job could not be stopped',
            type: 'error'
          })
        }
        setTimeout(() => {
          this.checkJobStatus('forceStart')
        }, 3000)
      }).catch((err) => {
        addAlert({
          message: `Error in stopping bus stop sync job: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
          type: 'error'
        })
      }).finally(() => {
        this.checkingCurrentJobStatus = false
      })
    },
    runCheckJobStatus (once) {
      if (this.selectedRegion) {
        getBusStopJobStatus(this.selectedRegion.routerId).then((result) => {
          // let's refresh the bus stops list if 'busy' was the last state and current state is not 'busy'
          if (this.currentJobStatus === 'busy' && result.status !== 'busy') {
            this.searchBusStops()
          }
          this.currentJobStatus = result.status
          if (this.currentJobStatus === 'busy') {
            this.currentJobStatusText = 'Sync job in progress'
          } else if (this.currentJobStatus === 'error') {
            this.currentJobStatusText = 'Error in completing the sync job'
          } else if (this.currentJobStatus === 'pending') {
            this.currentJobStatusText = 'Waiting for job to be picked up by process worker'
          } else if (!this.currentJobStatus) {
            this.currentJobStatusText = 'Sync job can be started'
          }
          if (!once) {
            this.checkJobStatus()
          }
        }).catch((err) => {
          addAlert({
            message: `Error in retrieving bus stop job status: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        })
      }
    },
    async checkJobStatus (forceStart) {
      if (this.busStopTimeoutHandle) {
        clearTimeout(this.busStopTimeoutHandle)
      }
      if (forceStart) {
        await this.runCheckJobStatus()
      } else {
        this.busStopTimeoutHandle = setTimeout(() => {
          this.runCheckJobStatus()
        }, 30000)
      }
    },
    onRegionChange (region) {
      this.selectedRegion = region
    },
    onListBusStopClicked (busStop) {
      this.loadBusStopForm(busStop)
    },
    onMapBusStopClicked (feature, e) {
      this.apiInProgress = true
      const busStop = this.busStops.find((o) => (o.externalId === feature.properties.id))

      if (busStop) {
        this.apiInProgress = false
        this.loadBusStopForm(busStop)
      } else {
        findBusStops({ where: { externalId: feature.properties.id }, limit: 1 })
          .then((results) => {
            this.apiInProgress = false
            const busStop = results && results.length ? this.marshalBusStop(results[0]) : null

            if (busStop) {
              this.loadBusStopForm(busStop)
            } else {
              alert('No bus stop found from map')
            }
          })
          .catch(err => {
            this.apiInProgress = false

            addAlert({
              message: err,
              type: 'error'
            })
          })
      }
    },
    showLongLatOnMap (busStop) {
      this.longLatOnMap = busStop.longlat
    },
    async getBusStopsAsCsvData () {
      this.$data.csvDataFilename = `smarttransit-bus-stops-${moment().format('Y-MM-DD-HH:mm:ss')}.csv`
      const rowsPerPage = 2000
      const csvData = []
      // cycle through and get all the bus stop data in CSV format
      let filter = this.generateSearchFilter(1, rowsPerPage)
      const countResult = await findTotalBusStops(filter.where)
      if (countResult.count) {
        const pages = countResult.count > rowsPerPage ? Math.ceil(countResult.count / rowsPerPage) : 1
        const promises = []
        for (let i = 0; i < pages; i++) {
          filter = this.generateSearchFilter(i + 1, rowsPerPage)
          promises.push(findBusStops(filter))
        }
        const searchResults = await Promise.all(promises)
        csvData.push('Date Updated,Last Synced with OTP,Long/Lat,OTP ID,OTP Name,Name')
        searchResults.forEach((results) => {
          if (results && results.length) {
            results.forEach((result) => {
              const parsedResult = this.marshalBusStop(result)
              csvData.push(`"${parsedResult.dateUpdatedLabel}","${parsedResult.dateSyncedLabel}","${parsedResult.longlat ? parsedResult.longlat.join(', ') : ''}","${parsedResult.externalId}","${parsedResult.externalName ? parsedResult.externalName.replace(/"/g, '\\"') : ''}","${parsedResult.name ? parsedResult.name.replace(/"/g, '\\"') : ''}"`)
            })
          }
        })
        return csvData.join('\n')
      } else {
        throw new Error('No data found to download from the current search results')
      }
    },
    onGetBusStopsClick () {
      return new Promise((resolve, reject) => {
        if (confirm('Confirm downloading all ' + this.$data.totalBusStops + ' stops')) {
          return resolve(true)
        }
        resolve(false)
      })
    },
    loadBulkEdit () {
      this.lastParams = addParamsToLocation({ bulkedit: 1 }, this.$route, this.lastParams)
      this.$data.isBulkEdit = true
    },
    exitBulkEdit () {
      this.$data.closingBulkEdit = true
      this.$data.enableVirtualList = false
      setTimeout(() => {
        this.lastParams = addParamsToLocation({ bulkedit: undefined }, this.$route, this.lastParams)
        this.$data.isBulkEdit = false
        this.$data.busStopsInBulk = []
        this.$data.closingBulkEdit = false
      }, 500)
    }
  },
  beforeDestroy () {
    if (this.initBulkEditVisibleRecordUpdateChecksHandle) {
      clearTimeout(this.initBulkEditVisibleRecordUpdateChecksHandle)
    }
    if (this.busStopTimeoutHandle) {
      clearTimeout(this.busStopTimeoutHandle)
    }
    if (this.isBulkEditWatchHandle) {
      this.isBulkEditWatchHandle()
      this.isBulkEditWatchHandle = null
    }
  }
}
